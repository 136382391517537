<template>
  <grupos-arquivos-data-table
    class="px-10"
    :items="items"
    @rechargeTable="fetchData()"
  />
</template>

<script>
import GruposArquivosService from '@/services/GruposArquivosService.js';

export default {
  name: "GruposArquivosIndex",

  components: {
    GruposArquivosDataTable: () => import("@/components/dados/arquivos-dados/grupos-arquivos/GruposArquivosDataTable.vue"),
  },

  data() {
    return {
      items: [],
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      GruposArquivosService.getAll()
        .then(res => {
          this.items = res.data.map((grupo) => ({
            id: grupo.id,
            nome: grupo.nome,
            num_arquivos: grupo.num_arquivos,
            ativo: grupo.status,
            criado_por: grupo.usuario.name
          }));
        })
        .catch(err => {
          this.$toast.error("Erro ao obter Grupos de Arquivos.", "", { position: "topRight"});
          console.error(err);
        });
    },
  }
  
}
</script>