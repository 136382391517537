import APIService from "./APIService";

export default {
    getAll() {
        return APIService.apiCall().get('/grupos-arquivos');
    },
    getAllActive() {
        return APIService.apiCall().get('/grupos-arquivos/active');
    },
    get(id) {
        return APIService.apiCall().get(`/grupos-arquivos/get-by-id/${id}`);
    },
    save(postData) {
        return APIService.apiCall().post(
            `/grupos-arquivos/save`, 
            JSON.stringify(postData));
    },
    toggleStatus(id) {
        return APIService.apiCall().put(`/grupos-arquivos/toggle-status/${id}`);
    }
};